import { IGoogleSpreadsheet, IGoogleSheetRow } from "../core/models";
import { GoogleSpreadsheet } from 'google-spreadsheet';
import { environment } from "../environment";

class GoogleSheetsService {
    constructor() {

    }

    async addToSheet(spreadSheet: IGoogleSpreadsheet, row: IGoogleSheetRow) {
        // Initialize the sheet - doc ID is the long id in the sheets URL
        const doc = new GoogleSpreadsheet(spreadSheet.id);
        try {
            // const credentials = fetch("/")
            // Initialize Auth - see https://theoephraim.github.io/node-google-spreadsheet/#/getting-started/authentication
            await doc.useServiceAccountAuth({
                // env var values are copied from service account credentials generated by google
                // see "Authentication" section in docs for more info
                client_email: environment.GOOGLESHEETS_CLIENT_EMAIL,
                private_key: environment.GOOGLESHEETS_PRIVATEKEY,
            });

            await doc.loadInfo();

            const sheet = doc.sheetsById[spreadSheet.sheetId];
            await sheet.addRow(row as any, {
                raw: false,
                insert: true,
            });
        } catch (error) {
            console.error('Error: ', error);
        }
    }
}

export default new GoogleSheetsService();