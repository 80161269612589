import * as React from "react"
import "../styles/index.scss";
import "../styles/responsive.scss";
import { StaticImage } from "gatsby-plugin-image";
import { FibsrSubscriberSheetRow, IGoogleSpreadsheet } from "../core/models";
import { useState } from "react";
import googleSheetService from "../services/google-sheet.service";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "gatsby";
import fibserSvg from '../images/svg/fibser-svg.svg';
import btnArrow from '../images/svg/button-right-arrow.svg';
import fibserWhiteSvg from '../images/svg/fibser-white-svg.svg';
import { Helmet } from 'react-helmet';

const IndexPage = () => {
    const [subscribeTopErrorMessage, setSubscribeTopErrorMessage] = useState("");
    const [subscribeBottomErrorMessage, setSubscribeBottomErrorMessage] = useState("");
    const [subscribing, setSubscribing] = useState(false);

    const onSubscribeNow = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        if (!email) {
            return Promise.reject("Please enter your email address");
        } else if (!emailRegex.test(email)) {
            return Promise.reject("Please enter a valid email address");
        } else {
            const spreadSheet: IGoogleSpreadsheet = {
                id: '1PPi617JWtTueZkVk14Il7q0M5cB8PBMeF-1O_8axbJg',
                sheetId: '0'
            };
            const row: FibsrSubscriberSheetRow = {
                email,
                subscribedOn: new Date().toISOString(),
            };

            setSubscribing(true);
            return googleSheetService.addToSheet(spreadSheet, row).then(() => {
                toast.success("You are now subscribed to our newsletter 🎉");
            }).finally(() => {
                setSubscribing(false);
            });
        }
    }

    return (
        <>
            <Helmet
                title={"Fibsr"}
            />
            <ToastContainer position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false} />

            <main className="home-page">
                <header className="homepage-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="main-wrapper">
                                    <img src={fibserSvg} />
                                    <div className="right-section">
                                        <Link to="/about-us">About Us</Link>
                                        {/* <a href="#">Contact</a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="homepage-body">
                    <section className="build-your-app">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="main-wrapper">
                                        <div className="main-text">Build your APP without Coding</div>
                                        <div className="sub-text">
                                            <div>Discover the platform that gives you the freedom to create,</div>
                                            <div>design, manage and develop your App presence exactly the way you want.</div>
                                        </div>
                                        <div className="subscribe-wrapper">
                                            <div className="subscribe-main">
                                                <input id="subscribe-email-top" type="text" placeholder="Enter Email Address" className="for-homepage" />
                                                <button className="btn btn-primary" disabled={subscribing} onClick={() => {
                                                    const inputElement = document.getElementById("subscribe-email-top") as HTMLInputElement;
                                                    if (subscribeTopErrorMessage) {
                                                        setSubscribeTopErrorMessage('');
                                                    }
                                                    onSubscribeNow(inputElement.value).catch((reason) => {
                                                        setSubscribeTopErrorMessage(reason);
                                                        inputElement.value = '';
                                                    });
                                                }}>
                                                    <span>Subscribe Now</span>
                                                    <img src={btnArrow} className="next-arrow" />
                                                </button>
                                                {
                                                    subscribeTopErrorMessage ? (<div className="email-error">{subscribeTopErrorMessage}</div>) : null
                                                }
                                            </div>
                                            {/* <img src="../images/semi-sphere.svg" className="img-fluid semi-sphere" /> */}
                                            {/* <StaticImage width={70} className="img-fluid semi-sphere" src={'../images/Sphere_a.png'}  placeholder="blurred" alt='' /> */}
                                        </div>
                                        <StaticImage draggable={false} className="img-fluid module-image" src={'../images/build-your-app.png'} placeholder="blurred" alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="easy-sync">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="text-part">
                                        <div className="main-heading">Easy Sync</div>
                                        <div className="description-text">Create your own platform on the Mobile app. Nevercode is a powerful app for new-media creators to publish, share, and grow a business around their content. It comes with modern tools to build a website, publish content, send newsletters & offer paid</div>
                                        <button className="btn btn-primary">
                                            <span>Start Building App</span>
                                            <img src={btnArrow} className="next-arrow" />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <StaticImage draggable={false} className="img-fluid module-img for-web" src={'../images/easy-sync-purple.png'} placeholder="blurred" alt='' />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="drag-drop">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6 for-web">
                                    <StaticImage draggable={false} className="img-fluid module-img" src={'../images/drag-drop-purple.png'} placeholder="blurred" alt='' />
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="text-part">
                                        <div className="main-heading">Drag and Drop</div>
                                        <div className="description-text">Create your own platform on the Mobile app. Nevercode is a powerful app for new-media creators to publish, share, and grow a business around their content. It comes with modern tools to build a website, publish content, send newsletters & offer paid</div>
                                        <button className="btn btn-primary">
                                            <span>Start Building App</span>
                                            <img src={btnArrow} className="next-arrow" />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 for-mobile">
                                    <StaticImage draggable={false} className="img-fluid module-img" src={'../images/drag-drop-purple.png'} placeholder="blurred" alt='' />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="easy-to-market">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="text-part">
                                        <div className="main-heading">Easy to market</div>
                                        <div className="description-text">Create your own platform on the Mobile app. Nevercode is a powerful app for new-media creators to publish, share, and grow a business around their content. It comes with modern tools to build a website, publish content, send newsletters & offer paid</div>
                                        <button className="btn btn-primary">
                                            <span>Start Building App</span>
                                            <img src={btnArrow} className="next-arrow" />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <StaticImage draggable={false} className="img-fluid module-img for-web" src={'../images/easy-market-purple.png'} placeholder="blurred" alt='' />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="browse-template">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="main-wrapper">
                                        <div className="heading-text">
                                            Design and build your own <span>high-quality APP</span>.<br />
                                            Whether you're promoting your business, showcasing
                                            your work, opening your store or starting a blog—you can do
                                            it all with the Nevercode.
                                        </div>
                                        {/* <button className="btn btn-primary">
                                        <span>Browse Template</span>
                                        <StaticImage className="next-arrow" src={'../images/next-arrow.svg'}  placeholder="blurred"  alt='' />
                                    </button> */}
                                        <StaticImage draggable={false} className="img-fluid module-image for-web" src={'../images/browse-template.png'} placeholder="blurred" alt='' />
                                        {/* <StaticImage className="img-fluid module-image for-mobile" src={'../images/browse-template-mobile.svg'}  placeholder="blurred"  alt='' /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="build-your-app in-footer-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="main-wrapper">
                                        <div className="main-text">Make your own app Today!!!</div>
                                        <div className="subscribe-wrapper">
                                            <div className="subscribe-main">
                                                <input id="subscribe-email-bottom" type="text" placeholder="Enter Email Address" className="for-homepage" />
                                                <button className="btn btn-primary" disabled={subscribing} onClick={() => {
                                                    const inputElement = document.getElementById("subscribe-email-bottom") as HTMLInputElement;
                                                    if (subscribeBottomErrorMessage) {
                                                        setSubscribeBottomErrorMessage('');
                                                    }
                                                    onSubscribeNow(inputElement.value).catch((reason) => {
                                                        setSubscribeBottomErrorMessage(reason);
                                                        inputElement.value = '';
                                                    });
                                                }}>
                                                    <span>Subscribe Now</span>
                                                    <img src={btnArrow} className="next-arrow" />
                                                </button>
                                                {
                                                    subscribeBottomErrorMessage ? (<div className="email-error">{subscribeBottomErrorMessage}</div>) : null
                                                }
                                            </div>
                                        </div>
                                        {/* <StaticImage className="img-fluid module-image" src={'../images/build-your-app.svg'}  placeholder="blurred"  alt='' /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="footer-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="main-wrapper">
                                        <img src={fibserWhiteSvg} className="img-fluid footer-logo" />
                                        <div className="right-section">
                                            <Link to="/about-us">About Us</Link>
                                            {/* <a href="#">Contact</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>

    )
}

export default IndexPage